/* eslint-disable max-len */
export const SearchNormalIcon = ({className}) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="search-normal" transform="translate(-108 -188)">
        <path id="Vector" d="M18,9A9,9,0,1,1,9,0,9,9,0,0,1,18,9Z" transform="translate(110 190)" fill="none" stroke="#06152b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        <path id="Vector-2" data-name="Vector" d="M.1,1.97c.53,1.6,1.74,1.76,2.67.36C3.62,1.05,3.06,0,1.52,0A1.406,1.406,0,0,0,.1,1.97Z" transform="translate(126.83 206.72)" fill="none" stroke="#06152b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(108 188)" fill="none" opacity="0"/>
      </g>
    </svg>

  );
};

