import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Fragment, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import MainArea from "../Layouts/Users/MainArea";
import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

export const renderRoutes = (routes = []) => {
  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
        <ScrollToTop />
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Component = route.component;
            const Layout = route.layout || Fragment;

            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => {
                  return (
                    <Guard>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <Component {...props} />
                        )}
                      </Layout>
                    </Guard>
                  );
                }}
              />
            );
          })}
        </Switch>
      </Suspense>
      <ToastContainer />
    </>
  );
};

export const routes = [
  {
    exact: true,
    path: `/`,
    layout: MainArea,
    component: lazy(() => import("../Views/Users/Cars/allCars")),
  },
  {
    exact: true,
    path: `/all`,
    layout: MainArea,
    component: lazy(() => import("../Views/Users/Cars/allCars")),
  },
  {
    exact: true,
    path: `/search`,
    layout: MainArea,
    component: lazy(() => import("../Views/Users/Cars/carSearchResult")),
  },
  {
    exact: true,
    path: `/seller/:sellerId`,
    layout: MainArea,
    component: lazy(() => import("../Views/Users/Seller")),
  },
  {
    exact: true,
    path: `/category/:category`,
    layout: MainArea,
    component: lazy(() => import("../Views/Users/Cars/allCars")),
  },
  {
    exact: true,
    path: `/category`,
    layout: MainArea,
    component: lazy(() => import("../Views/Users/Cars/allCars")),
  },
  {
    exact: true,
    path: "/:listingId",
    layout: MainArea,
    component: lazy(() => import("../Views/Users/Cars/carDetails")),
  },

  {
    path: "*",
    routes: [
      {
        component: lazy(() => import("../Components/Errors/Notfound")),
      },
    ],
  },
];
