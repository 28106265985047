import { ListingTypes } from "./listings.types";

const INITIAL_STATE = {
  featured_listings: {
    cars: [],
    real_estates: [],
    deals: [],
    jobs: [],
    events: [],
  },
  loading: false,
  listing: {},
  listings: [],
  categories: [],
  category: {},
  sub_categories: [],
  user: {},
  fetchFailed: false

};

const listingReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ListingTypes.FETCH_CARS_FEATURED_LISTING_PROCESS:
      return { ...state, loading: true, featured_listings: { ...state.featured_listings, cars: [] } };
    case ListingTypes.FETCH_CARS_FEATURED_LISTING_SUCCESS:
      return { ...state, loading: false, featured_listings: { ...state.featured_listings, cars: payload } };

    case ListingTypes.FETCH_REAL_ESTATES_FEATURED_LISTING_PROCESS:
      return { ...state, loading: true, featured_listings: { ...state.featured_listings, real_estates: [] } };
    case ListingTypes.FETCH_REAL_ESTATES_FEATURED_LISTING_SUCCESS:
      return { ...state, loading: false, featured_listings: { ...state.featured_listings, real_estates: payload } };

    case ListingTypes.FETCH_EVENTS_FEATURED_LISTING_PROCESS:
      return { ...state, loading: true, featured_listings: { ...state.featured_listings, events: [] } };
    case ListingTypes.FETCH_EVENTS_FEATURED_LISTING_SUCCESS:
      return { ...state, loading: false, featured_listings: { ...state.featured_listings, events: payload } };

    case ListingTypes.FETCH_JOBS_FEATURED_LISTING_PROCESS:
      return { ...state, loading: true, featured_listings: { ...state.featured_listings, jobs: [] } };
    case ListingTypes.FETCH_JOBS_FEATURED_LISTING_SUCCESS:
      return { ...state, loading: false, featured_listings: { ...state.featured_listings, jobs: payload } };

    case ListingTypes.FETCH_DEALS_FEATURED_LISTING_PROCESS:
      return { ...state, loading: true, featured_listings: { ...state.featured_listings, deals: [] } };
    case ListingTypes.FETCH_DEALS_FEATURED_LISTING_SUCCESS:
      return { ...state, loading: false, featured_listings: { ...state.featured_listings, deals: payload } };

    case ListingTypes.FETCH_SINGLE_LISTING_DETAILS_PROCESS:
      return { ...state, loading: true, listing: [] };
    case ListingTypes.FETCH_SINGLE_LISTING_DETAILS_SUCCESS:
      return { ...state, loading: false, listing: payload };

      case ListingTypes.FETCH_SINGLE_LISTING_DETAIL_FAILED:
        return { ...state, loading: false, fetchFailed:true, listing: payload };
  
    case ListingTypes.FETCH_LISTING_CATEGORIES_PROCESS:
      return { ...state, loading: true, categories: [] };
    case ListingTypes.FETCH_LISTING_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: payload };

    case ListingTypes.FETCH_LISTING_CATEGORY_PROCESS:
      return { ...state, loading: true, category: [] };
    case ListingTypes.FETCH_LISTING_CATEGORY_SUCCESS:
      return { ...state, loading: false, category: payload };

    case ListingTypes.FETCH_LISTING_SUBCATEGORIES_PROCESS:
      return { ...state, loading: true, sub_categories: [] };
    case ListingTypes.FETCH_LISTING_SUBCATEGORIES_SUCCESS:
      return { ...state, loading: false, sub_categories: payload };

    case ListingTypes.FETCH_LISTINGS_PROCESS:
      return { ...state, loading: true, listings: [] };
    case ListingTypes.FETCH_LISTINGS_SUCCESS:
      return { ...state, loading: false, listings: payload };

    case ListingTypes.FETCH_USER_DETAIL_PROCESS:
      return { ...state, loading: true, user: [] };
    case ListingTypes.FETCH_USER_DETAIL_SUCCESS:
      return { ...state, loading: false, user: payload };
    default:
      return state;
  }
};

export default listingReducer;