import axios from 'axios';
import { deleteCookie } from '../utils/utils';
import ConnectNigeria from './ConnectNigeria';

export const authRequests = {
  requestLogin,
  requestSocialLogin,
  requestLogout,
  requestRegister
};

async function requestLogin(email, password) {
  return await ConnectNigeria.post('/user/auth/login', {
    email,
    password
  })
    .then(res => {
      return {
        success: true,
        data: res.data
      };
    })
    .catch(err => {
      return {
        success: false,
        error: err.response.data
      };
    });
}

function requestLogout() {
  deleteCookie('auth:hash');
}

async function requestRegister(data) {
  return await ConnectNigeria.post('/user/auth/register', {
    ...data,
    callbackUrl: window.location.origin
  })
    .then(res => {
      return {
        success: true,
        data: res.data
      };
    })
    .catch(err => {
      return {
        success: false,
        error: err.response.data
      };
    });
}


// TODO: Everything below this line
function requestSocialLogin(userId, access_token, provider) {
  const headers = {
    'Content-Type': 'application/json'
  };
  return axios
    .post(
      process.env.REACT_APP_API_USER_URL + '/auth/social/' + provider,
      {
        userId,
        access_token,
        callbackUrl: process.env.REACT_APP_CN_BASE_URL + '/verify'
      },
      headers
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      var errData = {
        ...error.response.data,
        message: error.response.data.trace
          ? 'An error occured. Please try again.'
          : error.response.data.message
      };
      return errData;
    });
}