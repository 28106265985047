import axios from "axios";
import { getToken } from "./helpers";
import { authRequests } from "./auth.requests";

const ConnectNigeria = axios.create();

ConnectNigeria.interceptors.request.use(config => {
  config.baseURL = process.env.REACT_APP_API_HOST;
  config.headers['Authorization'] = getToken() && `Bearer ${getToken()}`;
  config.headers['Content-Type'] = 'application/json';
  config.headers['Accept'] = 'application/json';
  return config;
}, error => {
  return Promise.reject(error);
});

    
ConnectNigeria.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401 || error.response.status === 403) {
      authRequests.requestLogout();
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `${process.env.REACT_APP_CN_BASE_URL}/login?redirect=${currentUrl}`;
      return;
    }
    return Promise.reject(error);
  });
    
    
    

export default ConnectNigeria;


