import {
  requestContactSeller,
  requestContactUs,
  requestCreateReview,
  requestFetchAllListingCategory,
  requestFetchFeaturedListings,
  requestFetchListingCategory,
  requestFetchListingDetail,
  requestFetchListings,
  requestFetchListingSubCategory,
  requestFetchListingThatBelongsToABusiness,
  requestFetchListingThatBelongsToASeller,
  requestFetchUserDetail,
  // requestFetchListingThatBelongsToTheOwner,
  requestLikeAModel,
  requestSearchListing
} from "../../Services/listings.requests";
import { alertActions } from "../alert/alert.actions";
import { ListingTypes } from "./listings.types";

/**
 * 
 * Fetch featured listing under cars.

 * @param {*} listingCategoryId 
 * @returns 
 */
export const fetchCarsFeaturedListings = (listingCategoryId, per_page, page_no) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_CARS_FEATURED_LISTING_PROCESS
    });

    try {
      const { data: { data: { listings } } } = await requestFetchFeaturedListings(listingCategoryId, per_page, page_no);

      dispatch({
        type: ListingTypes.FETCH_CARS_FEATURED_LISTING_SUCCESS,
        payload: listings
      });

    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
};

/**
 * 
 * Fetch featured listing under real estates.

 * @param {*} listingCategoryId 
 * @returns 
 */
export const fetchRealEstatesFeaturedListings = (listingCategoryId, per_page, page_no) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_REAL_ESTATES_FEATURED_LISTING_PROCESS
    });

    try {
      const { data: { data: { listings } } } = await requestFetchFeaturedListings(listingCategoryId, per_page, page_no);

      dispatch({
        type: ListingTypes.FETCH_REAL_ESTATES_FEATURED_LISTING_SUCCESS,
        payload: listings
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response.data);
    }
  };
};

/**
 * 
 * Fetch featured listing under deals.

 * @param {*} listingCategoryId 
 * @returns 
 */
export const fetchDealsFeaturedListings = (listingCategoryId, per_page, page_no) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_DEALS_FEATURED_LISTING_PROCESS
    });

    try {
      const { data: { data: { listings } } } = await requestFetchFeaturedListings(listingCategoryId, per_page, page_no);

      dispatch({
        type: ListingTypes.FETCH_DEALS_FEATURED_LISTING_SUCCESS,
        payload: listings
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err?.response?.data);
    }
  };
};

/**
 * 
 * Fetch featured listing under jobs.

 * @param {*} listingCategoryId 
 * @returns 
 */
export const fetchJobsFeaturedListings = (listingCategoryId, per_page, page_no) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_JOBS_FEATURED_LISTING_PROCESS
    });

    try {
      const { data: { data: { listings } } } = await requestFetchFeaturedListings(listingCategoryId, per_page, page_no);

      dispatch({
        type: ListingTypes.FETCH_JOBS_FEATURED_LISTING_SUCCESS,
        payload: listings
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response.data);
    }
  };
};

/**
 * 
 * Fetch featured listing under events.

 * @param {*} listingCategoryId 
 * @returns 
 */
export const fetchEventsFeaturedListings = (listingCategoryId, per_page, page_no) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_EVENTS_FEATURED_LISTING_PROCESS
    });

    try {
      const { data: { data: { listings } } } = await requestFetchFeaturedListings(listingCategoryId, per_page, page_no);

      dispatch({
        type: ListingTypes.FETCH_EVENTS_FEATURED_LISTING_SUCCESS,
        payload: listings
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response.data);
    }
  };
};

/**
 * Fetch a single listing detail.
 * 
 * @param {*} listingCategoryId 
 * @param {*} listingId 
 * @returns 
 */
export const fetchSingleListing = (listingCategoryId, listingId) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_SINGLE_LISTING_DETAILS_PROCESS
    });

    try {
      const { data: { data: { listing } } } = await requestFetchListingDetail(listingCategoryId, listingId);
      dispatch({
        type: ListingTypes.FETCH_SINGLE_LISTING_DETAILS_SUCCESS,
        payload: listing
      });
    } catch (err) {
      dispatch({
        type: ListingTypes.FETCH_SINGLE_LISTING_DETAIL_FAILED,
      });
      dispatch(alertActions.error("Listing not found"));

      // eslint-disable-next-line no-console
      console.log(err.response.data);
    }
  };
};

/**
 * Submit Contact Details for 
 * even guest user.
 * 
 * @param {*} contactDetail 
 * @returns 
 */
export const submitContactDetail = (contactDetail) => {
  return async () => {
    try {
      await requestContactUs(contactDetail);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response.data);
    }
  };
};

export const fetchListingCategories = () => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_LISTING_CATEGORIES_PROCESS
    });

    try {
      const { data: { data: { listing_categories } } } = await requestFetchAllListingCategory();

      dispatch({
        type: ListingTypes.FETCH_LISTING_CATEGORIES_SUCCESS,
        payload: listing_categories
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response.data);
    }
  };
};

export const fetchListingCategory = (listingCategoryId) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_LISTING_CATEGORY_PROCESS
    });

    try {
      const { data: { data: { listing_category } } } = await requestFetchListingCategory(listingCategoryId);

      dispatch({
        type: ListingTypes.FETCH_LISTING_CATEGORY_SUCCESS,
        payload: listing_category
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response.data);
    }
  };
};

export const fetchListingSubCategories = (listingCategoryId) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_LISTING_SUBCATEGORIES_PROCESS
    });

    try {
      const { data: { data: { listing_sub_categories } } } = await requestFetchListingSubCategory(listingCategoryId);

      dispatch({
        type: ListingTypes.FETCH_LISTING_SUBCATEGORIES_SUCCESS,
        payload: listing_sub_categories
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response.data);
    }
  };
};

export const fetchListings = (listingCategoryId, per_page, page_no, subCategoryId) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_LISTINGS_PROCESS
    });

    try {
      const { data: { data: { listings } } } = await requestFetchListings(listingCategoryId, per_page, page_no, subCategoryId);

      dispatch({
        type: ListingTypes.FETCH_LISTINGS_SUCCESS,
        payload: listings
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
};

export const searchListing = (keyword, category, listingCategory, page_no, filterOptions = []) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_LISTINGS_PROCESS
    });

    try {
      const { data: { data } } = await requestSearchListing(keyword, category, listingCategory, page_no, filterOptions);

      dispatch({
        type: ListingTypes.FETCH_LISTINGS_SUCCESS,
        payload: data
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response.data);
    }
  };
};

export const createReview = (listingId, comment, rating, model = 'listing') => {
  return async (dispatch, getState) => {
    try {
      const body = { model, model_id: listingId, comment, rating };

      const listingCategoryId = process.env.REACT_APP_LISTING_CATEGORY_ID;
      const listingSlug = getState()?.listingsReducer?.listing?.slug;

      const { data: { message } } = await requestCreateReview(body);
      dispatch(alertActions.success(message));
      dispatch(fetchSingleListing(listingCategoryId, listingSlug));
    } catch (err) {
      const { errors, message } = err?.response?.data;

      dispatch(alertActions.error(message));

      if (errors.comment) {
        dispatch(alertActions.error(errors.comment[0]));
      }

      if (errors.rating) {
        dispatch(alertActions.error(errors.rating[0]));
      }
    }
  };
};

export const conatctSeller = (name, email, phone_number, text) => {
  return async dispatch => {
    try {
      const { data: { message } } = await requestContactSeller({ name, email, phone_number, message: text, subject: 'A Vistor From Connect Nigeria' });

      dispatch(alertActions.success(message));
    } catch (err) {
      const { message } = err?.response?.data;

      dispatch(alertActions.error(message));
    }
  };
};

export const likeListing = (listingId) => {
  return async (dispatch, getState) => {
    try {
      const { data: { message } } = await requestLikeAModel({ model: 'listing', model_id: listingId });

      dispatch(fetchSingleListing(process.env.REACT_APP_LISTING_CATEGORY_ID, getState()?.listingsReducer?.listing?.slug));
      dispatch(alertActions.success(message));
    } catch (err) {
      const { message } = err?.response?.data;

      dispatch(alertActions.error(message));
    }
  };
};

export const fetchBusinessListing = (businessId) => {

  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_LISTINGS_PROCESS
    });

    try {
      const { data: { data: { listings } } } = await requestFetchListingThatBelongsToABusiness(businessId);

      dispatch({
        type: ListingTypes.FETCH_LISTINGS_SUCCESS,
        payload: listings
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
};

export const fetchUserDetail = (userId) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_USER_DETAIL_PROCESS
    })


    try {
      const { data: { data: { user } } } = await requestFetchUserDetail(userId)

      dispatch({
        type: ListingTypes.FETCH_USER_DETAIL_SUCCESS,
        payload: user
      })
    } catch (err) {
      const { message } = err?.response?.data;

      dispatch(alertActions.error(message));
    }
  }
}

export const fetchOwnerListing = (ownerId) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_LISTINGS_PROCESS
    })

    try {
      const { data: { data: { listings } } } = await requestFetchListingThatBelongsToASeller(ownerId);

      dispatch({
        type: ListingTypes.FETCH_LISTINGS_SUCCESS,
        payload: listings
      });
    }catch(err){
      const { message } = err?.response?.data;

      dispatch(alertActions.error(message));
    }
  }
}