import { combineReducers } from 'redux';
import listingsReducer from '../../redux/listings/listings.reducer';
import authReducer from '../../redux/auth/auth.reducers';
import { alertReducer } from '../../redux/alert/alert.reducers';
import { uiReducer } from '../../redux/ui/ui.reducers';

const rootReducer = combineReducers({
  listingsReducer,
  authReducer,
  alertReducer,
  uiReducer

});

export default rootReducer;
